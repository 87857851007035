<template>
  <cui-acl  :roles="['talkmoreB2Csettings', 'talkmoreB2Cep']" redirect>
    <div class="kit__utils__heading">
      <h5>
        <span class="mr-3">Talkmore Privat EP Brukere</span>
        <a-button style="float: right;" @click="downloadData()">
          Last ned informasjon
        </a-button>
        <a-input placeholder="Søk ep nummer eller navn" @change="search" class="col-md-3" style="float: right;"/>
      </h5>
    </div>
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="{ pageSize: 50 }">
      <template slot="epuser" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record.key, 'epuser', $event)" />
      </template>
    </a-table>
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import { mapState } from 'vuex'
import exportFromJSON from 'export-from-json'
import EditableCell from '@/components/custom/visual/editableCell'
import { openNotification } from '@/services/powerAutomate'

function onChange(pagination, filters, sorter) {
  console.log('params', pagination, filters, sorter)
}

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
    EditableCell,
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dataSource: [],
      oldData: [],
      count: 0,
      columns: [
        {
          title: 'Ansatt ID',
          dataIndex: 'employeeID',
        },
        {
          title: 'Ansatt',
          dataIndex: 'name',
        },
        {
          title: 'EP Bruker',
          dataIndex: 'epuser',
          scopedSlots: { customRender: 'epuser' },
        },
        {
          title: 'Avdeling',
          dataIndex: 'department',
        },
        {
          title: 'Status ansettelse',
          dataIndex: 'status',
        },
      ],
    }
  },
  methods: {
    onChange,
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.dataSource = dataSource
        console.log(target)
        firebase.firestore().collection('users').doc(target.id)
          .update({
            talkmoreB2C: target.epuser,
          }).then(() => {
            openNotification(`Ep bruker oppdatert for ${target.name}`)
          })
        this.$forceUpdate()
      }
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    downloadData() {
      const data = this.dataSource
      const fileName = 'ep user data'
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      exportFromJSON({ data, fileName, exportType, delimiter })
    },
    search(e) {
      const search = e.target.value
      const data = this.oldData
      this.dataSource = data.filter(el => {
        let state = el.epuser.toString().toLowerCase().includes(search.toString().toLowerCase())
        if (!state) {
          state = el.name.toString().toLowerCase().includes(search.toString().toLowerCase())
        }
        return state
      })
    },
  },
  mounted() {
    // create a search bar on top to filter any value for user const res = arr.filter(obj => Object.values(obj).some(val => val.includes(search)));
    firebase.firestore().collection('users').orderBy('fullName').orderBy('talkmoreB2C')
      .get().then(snapshot => {
        this.dataSource = []
        this.count = 0
        snapshot.forEach(doc => {
          if (doc.data().talkmoreB2C.length) {
            this.dataSource.push({
              key: this.count,
              id: String(doc.id),
              employeeID: String(doc.data().employeeID),
              name: String(doc.data().fullName),
              epuser: (doc.data().talkmoreB2C),
              department: (doc.data().department),
              status: doc.data().status,
            })
            this.count += 1
          }
        })
        this.oldData = this.dataSource
      })
  },
}
</script>
